<template>
  <div id="app">
    <router-view />

    <div class="limiter">
      <div class="container-table100 m-0">
        <div class="wrap-table100">
          <a
            href="https://play.google.com/store/apps/details?id=work.ashmawi.lifeblood"
            target="_blank"
            rel="noopener noreferrer"
            class="googlePlayLink mt-3 clearfix"
          >
            <img
              src="/google-play-badge.png"
              alt=""
            >
          </a>
          <div class="footerApp">
            <div class="row">
              <div class="col-md-8">
                <div class="float-right">
                  باستخدامك هذا الموقع، فإنك توافق على
                  <span
                    id="show-privacy_terms"
                    class="isClick"
                    @click="showModalPrivacyPerms = true"
                  >شروط الخدمة و سياسية الخصوصية</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="float-left">
                  <span
                    id="show-modal"
                    class="isClick"
                    @click="showModal = true"
                  >من نحن</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- use the modal component, pass in the prop -->
    <modal
      v-if="showModal"
      @close="showModal = false"
    >
      <h5 slot="header">
        من نحن
      </h5>
      <p slot="body">
        الموقع يقدم خدمة لمن هم في أمس الحاجه لقطرة دم
        <br>
        نحتاج منك الدعم بنشر الموقع والدعاء لنا في ظهر الغيب
      </p>
    </modal>

    <!-- use the modal component, pass in the prop -->
    <modal
      v-if="showModalPrivacyPerms"
      @close="showModalPrivacyPerms = false"
    >
      <h5 slot="header">
        شروط الخدمة و سياسية الخصوصية
      </h5>
      <div slot="body">
        <p>
          الهدف من الموقع هو لمساعدة محتاجي الدم فقط ويمنع منعا باتا استخدام محتويات الموقع لاهداف اخرى
          <br>
          المتبرعون وضعوا معلوماتهم لهدف نبيل ويرغبوا ان تستخدم معلوماتهم في هدف نبيل كذلك
          <br>
          للموقع كامل الحق في ملاحقة مخالفي خصوصية الموقع قانونياً
          <br>
          يرجاء العلم ان معلومات الاتصال سوف تظهر بشكل عام عند البحث على متبرعين
          <br>
          لحذف بياناتك راسلنا على info@ashmawi.work
        </p>
      </div>
    </modal>

    <gdpr v-if="gdpr" />
  </div>
</template>

<script>
import modal from './components/modal.vue';
import gdpr from './components/Gdpr.vue';

export default {
  name: 'App',
  components: {
    modal,
    gdpr,
  },
  data() {
    return {
      showModal: false,
      showModalPrivacyPerms: false,
      gdpr: false,
    };
  },
  created() {
    if (this.$cookies.get('GDPRI') == null) {
      this.gdpr = true;
    }
  },
};
</script>

<style lang="scss">
@import url("../node_modules/bootstrap/dist/css/bootstrap.min.css");

body {
  background: #f8f9fa;
  padding: 0;
  margin: 0;
}
#app {
  // font-family: Tahoma, Geneva, Verdana, sans-serif !important;
  font-family: 'Cairo', Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  width: 100%;
  padding: 20px 10px;
  align-items: center;
  display: flex;
  background-color: #2d132c;
  position: relative;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
  a.homeAreaLink {right: 20px;}
  a.volunteerLink {left: 20px;}
}
.container-table100 {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0px 30px;
  margin: 100px 0 5px;
}
.wrap-table100 {
  width: 960px;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .container-table100 {
    margin: 20px 0 5px;
    padding: 0px 15px;
  }
}
.form-control {
  padding: .1rem .8rem;
}
span.isClick {
  cursor: pointer;
  color: #6f0f2e;
}
.footerApp {
  padding: 0 10px;
  font-weight: 600;
  font-size: 14px;
  margin: 5px 0 20px;
}
.googlePlayLink {display: none;}
.Buy_Coffee {
  position: absolute;
  left: 15px;
  top: 20px;
  .innerBuy_Coffee {
    display: inline-flex;
    align-items: center;
  }
  a.kofi-button {height: 40px;}
  span.kofitext {
    font-family: 'Cairo', Tahoma, sans-serif !important;
    line-height: 0px !important;
    font-size: 12px;
  }
  img.kofiimg {
    display: initial!important;
    vertical-align: middle;
    height: 13px!important;
    width: 20px!important;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    margin-top: 0;
    margin-right: 5px;
    margin-left: 0;
    margin-bottom: 3px;
    content: url('https://cdn.ko-fi.com/cdn/cup-border.png')
  }

  .kofiimg:after {
    vertical-align: middle;
    height: 25px;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    margin-top: 0;
    margin-right: 6px;
    margin-left: 0;
    margin-bottom: 4px!important;
    content: url('https://cdn.ko-fi.com/cdn/whitelogo.svg')
  }
  .btn-container {
    white-space: nowrap;
    min-width: 160px;
  }
  span.kofitext {
    color: #fff !important;
    letter-spacing: -0.15px!important;
    text-wrap: none;
    vertical-align: middle;
    line-height: 0px !important;
    padding: 0;
    text-align: center;
    text-decoration: none!important;
    text-shadow: 0 1px 1px rgba(34, 34, 34, 0.05);
  }

  .kofitext a {
    color: #fff !important;
    text-decoration: none !important;
  }

  .kofitext a:hover {
    color: #fff !important;
    text-decoration: none
  }

  a.kofi-button {
    box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
    line-height: 36px!important;
    min-width: 150px;
    display: inline-block!important;
    background-color: #29abe0;
    padding: 2px 12px !important;
    text-align: center !important;
    border-radius: 7px;
    color: #fff;
    cursor: pointer;
    overflow-wrap: break-word;
    vertical-align: middle;
    border: 0 none #fff !important;
    font-family: 'Quicksand', Helvetica, Century Gothic, sans-serif !important;
    text-decoration: none;
    text-shadow: none;
    font-weight: 700!important;
    font-size: 14px !important
  }

  a.kofi-button:visited {
    color: #fff !important;
    text-decoration: none !important
  }

  a.kofi-button:hover {
    opacity: .85;
    color: #f5f5f5 !important;
    text-decoration: none !important
  }

  a.kofi-button:active {
    color: #f5f5f5 !important;
    text-decoration: none !important
  }

  img.kofiimg {
    height: 15px!important;
    width: 22px!important;
    display: initial;
    animation: kofi-wiggle 3s infinite;
  }

  @keyframes kofi-wiggle {
    0% {
      transform: rotate(0) scale(1)
    }
    60% {
      transform: rotate(0) scale(1)
    }
    75% {
      transform: rotate(0) scale(1.12)
    }
    80% {
      transform: rotate(0) scale(1.1)
    }
    84% {
      transform: rotate(-10deg) scale(1.1)
    }
    88% {
      transform: rotate(10deg) scale(1.1)
    }
    92% {
      transform: rotate(-10deg) scale(1.1)
    }
    96% {
      transform: rotate(10deg) scale(1.1)
    }
    100% {
      transform: rotate(0) scale(1)
    }
  }
}

@media screen and (max-width: 768px) {
  .footerApp {
    .float-right {float: inherit !important;}
    .float-left {
      float: inherit !important;
      margin-top: 5px;
    }
  }
  .googlePlayLink {display: block;}
  .Buy_Coffee {
    .innerBuy_Coffee {
      iframe {display: none;}
    }
  }
}
@media screen and (max-width: 568px) {
  .Buy_Coffee {
    .innerBuy_Coffee {
      iframe {display: none;}
    }
    .btn-container, a.kofi-button {
      width: inherit !important;
      min-width: 0 !important;
    }
    span.kofitext {
      font-size: 11px;
    }
  }
}
</style>
