<template>
  <div class="isAdm">
    <div
      v-if="isNeedGo"
      class="needGo"
    >
      <div class="container-table100">
        <div class="wrap-table100">
          <input
            v-model="isNeedGoTixt"
            type="text"
          >
          <button
            type="submit"
            @click="toNeedGo()"
          >
            Go
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="isInWay"
      class="inWay"
    >
      <div class="container-table100">
        <div class="wrap-table100">
          <div class="table">
            <div
              v-if="benefactors.length"
              class="row header"
            >
              <div class="cell">
                تفعيل
              </div>
              <div class="cell">
                الإســـم
              </div>
              <div class="cell">
                العنــوان
              </div>
              <div class="cell">
                الهاتف
              </div>
              <div class="cell">
                فصيلة الدم
              </div>
              <div class="cell">
                نوع التواصل
              </div>
              <div class="cell">
                وقت الإتصــال
              </div>
              <div class="cell">
                حذف
              </div>
            </div>

            <div
              v-for="benefactor of benefactors"
              :key="benefactor['.key']"
              class="row"
            >
              <div class="cell">
                <button type="submit" class="btn btn-success btn-sm" @click="confirmData(benefactor['.key'])">تاكيد</button> <!-- eslint-disable-line -->
              </div>
              <div class="cell">
                {{ benefactor.name }}
              </div>
              <div class="cell">
                {{ benefactor.city }}/{{ benefactor.region }}
              </div>
              <div class="cell">
                {{ benefactor.phone }}
              </div>
              <div class="cell">
                {{ bloodType(benefactor.bloodType) }}
              </div>
              <div class="cell">
                {{ bloodType(benefactor.contactType) }}
              </div>
              <div class="cell">
                {{ benefactor.phonbloodTimee }}
              </div>
              <div class="cell">
                <button type="submit" class="btn btn-danger btn-sm" @click="deletData(benefactor['.key'])">حذف</button> <!-- eslint-disable-line -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isBenefactor } from '../../firebase';

export default {
  name: 'Adm',
  data() {
    return {
      benefactors: [],
      isInWay: false,
      isNeedGo: true,
      isNeedGoTixt: '',
    };
  },
  firebase: {
    benefactors: isBenefactor
      .orderByChild('isReal')
      .equalTo(false)
      .limitToLast(30),
  },

  methods: {

    toNeedGo() {
      if (`${9 * 9}01286007601AS` == this.isNeedGoTixt) { //eslint-disable-line
        this.isInWay = true;
        this.isNeedGo = false;
      }
    },

    // eslint-disable-next-line
    bloodType: function(typeis) {
      if (typeis === 'Op') {
        return '+O';
      }
      if (typeis === 'Om') {
        return '-O';
      }
      if (typeis === 'Bp') {
        return '+B';
      }
      if (typeis === 'Bm') {
        return '-B';
      }
      if (typeis === 'Ap') {
        return '+A';
      }
      if (typeis === 'Am') {
        return '-A';
      }
      if (typeis === 'ABp') {
        return '+AB';
      }
      if (typeis === 'ABm') {
        return '-AB';
      }
      if (typeis === 'call') {
        return 'اتصال';
      }
      if (typeis === 'sms') {
        return 'رســائل';
      }
      if (typeis === 'all') {
        return 'اتصـــال او رســائل';
      }
    },

    confirmData(key) {
      isBenefactor.child(key).update({ isReal: true });
    },

    deletData(key) {
      const getVisi = isBenefactor.child(key);
      getVisi.remove();
    },

  },

};
</script>

<style lang="scss" scoped>

  #share-buttons .svg-icon {
    width: 20px;
  }
  #share-buttons a {
    margin: 0 5px;
    cursor: pointer;
  }
  .isIcon {
    width: 40px;
    height: 40px;
    line-height: 35px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    -webkit-transition: background 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: background 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transition: background 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: background 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    transition: background 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .isIcon:hover {
    background: rgba(0, 0, 0, 0.39);
  }

  .svg-icon {
    width: 26px;
    fill: #fff;
  }
  .plusAd {
    color: #fff;
    position: absolute;
    left: 5px;
  }
  .bloDet {
    line-height: 40px;
  }
  .isIcon.homeArea {
    float: right;
  }
  .isIcon.volunteer {
    float: left;
  }
  .isIcon.volunteer .svg-icon {
    margin-left: 6px;
  }
  .customDie .customDie_inner {
    text-align: right;
    padding: 10px 20px !important;
    background-color: #5f6dc7;
    font-size: 13px;
    color: #fff;
    .shareArea {
      float: right;
    }
    .allBloo {
      span {
        margin: 0 5px 0 0;
      }
    }
  }
  .customDie:hover {
    cursor: inherit;
  }
  .row.notFund {
    span {
      margin: 0 5px;
    }
  }

  /* ------------------------------------ */
  a {
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
  }

  a:focus {
    outline: none !important;
  }

  a:hover {
    text-decoration: none;
  }

  /* ------------------------------------ */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0px;
  }

  p {
    margin: 0px;
  }

  ul,
  li {
    margin: 0px;
    list-style-type: none;
  }

  /* ------------------------------------ */
  button {
    outline: none !important;
  }

  button:hover {
    cursor: pointer;
  }

  iframe {
    border: none !important;
  }

  /*//////////////////////////////////////////////////////////////////
  [ Table ]*/

  .limiter {
    width: 100%;
    margin: 0 auto;
  }
  .table {
    width: 100%;
    display: table;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    .table {
      display: block;
    }
  }

  .row {
    display: table-row;
    background: #fff;
  }

  .row.header {
    color: #ffffff;
    background: #6c7ae0;
  }

  @media screen and (max-width: 768px) {
    .row {
      display: block;
    }

    .row.header {
      padding: 0;
      height: 0px;
    }

    .row.header .cell {
      display: none;
    }

    .row .cell:before {
      font-size: 12px;
      color: #808080;
      line-height: 1.2;
      text-transform: uppercase;
      font-weight: unset !important;
      margin-bottom: 13px;
      content: attr(data-title);
      min-width: 98px;
      display: block;
    }
  }

  .cell {
    display: table-cell;
  }

  @media screen and (max-width: 768px) {
    .cell {
      display: block;
    }
  }

  .row .cell {
    font-size: 15px;
    color: #666666;
    line-height: 1.2;
    font-weight: unset !important;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #f2f2f2;
  }

  .row.header .cell {
    font-size: 18px;
    color: #fff;
    line-height: 1.2;
    font-weight: unset !important;
    padding-top: 19px;
    padding-bottom: 19px;
  }

  .row .cell:nth-child(1) {
    width: 120px;
  }

  .row .cell:nth-child(2) {
    width: 170px;
  }

  .row .cell:nth-child(3) {
    width: 170px;
  }

  .row .cell:nth-child(4) {
    width: 90px;
  }

  .table,
  .row {
    width: 100% !important;
  }
  @media (max-width: 768px) {
    .row {
      border-bottom: 1px solid #f2f2f2;
      margin: 0;
    }

    .customDie .cell {
      strong {
        font-size: 14px;
      }
      &:before {
        display: none;
      }
    }

    .row.notFund .cell {
      padding: 15px 10px !important;
      &:before {
        display: none;
      }
      h6 {
        font-size: 14px;
      }
    }

    .row .cell {
      border: none;
      padding-left: 30px;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .row .cell:nth-child(1) {
      padding-left: 30px;
    }

    .row .cell {
      font-size: 18px;
      color: #555555;
      line-height: 1.2;
      font-weight: unset !important;
    }

    .table,
    .row,
    .cell {
      width: 100% !important;
    }
  }

  .s003 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #6b7ae0;
    background-size: cover;
    background-position: center center;
    padding: 15px;
  }

  .s003 form {
    width: 100%;
    max-width: 790px;
    margin-bottom: 0;
    padding: 0px 0px 15px 0px;
    strong {
      margin-bottom: 15px;
      display: inline-block;
    }
  }

  .s003 form .inner-form {
    background: #fff;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    padding: 15px;
  }

  .s003 form .inner-form .input-field input {
    height: 100%;
    background: transparent;
    border: 0;
    display: block;
    width: 100%;
    padding: 10px 32px;
    font-size: 16px;
    color: #555;
  }

  .s003 form .inner-form .input-field input.placeholder {
    color: #888;
    font-size: 16px;
  }

  .s003 form .inner-form .input-field input:-moz-placeholder {
    color: #888;
    font-size: 16px;
  }

  .s003 form .inner-form .input-field input::-webkit-input-placeholder {
    color: #888;
    font-size: 16px;
  }

  .s003 form .inner-form .input-field input:hover,
  .s003 form .inner-form .input-field input:focus {
    box-shadow: none;
    outline: 0;
    border-color: #fff;
  }

  .s003 form .inner-form .input-field.first-wrap {
    width: 300px;
  }
  .s003 form .inner-form .input-field.first-wrap .choices__inner {
    background: transparent;
    border-radius: 0;
    border: 0;
    height: 100%;
    color: #fff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 30px;
  }

  .s003
    form
    .inner-form
    .input-field.first-wrap
    .choices__inner
    .choices__list.choices__list--single {
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
  }

  .s003
    form
    .inner-form
    .input-field.first-wrap
    .choices__inner
    .choices__item.choices__item--selectable.choices__placeholder {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    opacity: 1;
    color: #888;
  }

  .s003
    form
    .inner-form
    .input-field.first-wrap
    .choices__inner
    .choices__list--single
    .choices__item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    color: #555;
  }

  .s003
    form
    .inner-form
    .input-field.first-wrap
    .choices[data-type*="select-one"]:after {
    right: 30px;
    border-color: #e5e5e5 transparent transparent transparent;
  }

  .s003
    form
    .inner-form
    .input-field.first-wrap
    .choices__list.choices__list--dropdown {
    border: 0;
    background: #fff;
    padding: 20px 30px;
    margin-top: 2px;
    border-radius: 4px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  }

  .s003
    form
    .inner-form
    .input-field.first-wrap
    .choices__list.choices__list--dropdown
    .choices__item--selectable {
    padding-right: 0;
  }

  .s003
    form
    .inner-form
    .input-field.first-wrap
    .choices__list--dropdown
    .choices__item--selectable.is-highlighted {
    background: #fff;
    color: #63c76a;
  }

  .s003
    form
    .inner-form
    .input-field.first-wrap
    .choices__list--dropdown
    .choices__item {
    color: #555;
    min-height: 24px;
  }

  .s003 form .inner-form .input-field.second-wrap {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .s003 form .inner-form .input-field.third-wrap {
    width: 74px;
    height: 37px;
    border-radius: 3px;
    overflow: hidden;
    line-height: 0;
  }

  .s003 form .inner-form .input-field.third-wrap .btn-search {
    height: 100%;
    width: 100%;
    white-space: nowrap;
    color: #fff;
    border: 0;
    cursor: pointer;
    background: #63c76a;
    transition: all 0.2s ease-out, color 0.2s ease-out;
  }

  .s003 form .inner-form .input-field.third-wrap .btn-search svg {
    width: 16px;
  }

  .s003 form .inner-form .input-field.third-wrap .btn-search:hover {
    background: #50c058;
  }

  .s003 form .inner-form .input-field.third-wrap .btn-search:focus {
    outline: 0;
    box-shadow: none;
  }

  @media screen and (max-width: 992px) {
    .s003 form .inner-form .input-field {
      height: 50px;
    }
  }

  @media screen and (max-width: 767px) {
    .s003 form .inner-form {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding: 20px;
    }
    .s003 form .inner-form .input-field {
      margin-bottom: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .s003 form .inner-form .input-field input {
      padding: 10px 15px;
    }
    .s003 form .inner-form .input-field.first-wrap {
      width: 100%;
      border-right: 0;
    }
    .s003 form .inner-form .input-field.first-wrap .choices__inner {
      padding: 10px 15px;
    }
    .s003
      form
      .inner-form
      .input-field.first-wrap
      .choices[data-type*="select-one"]:after {
      right: 11.5px;
      border-color: #e5e5e5 transparent transparent transparent;
    }
    .s003 form .inner-form .input-field.second-wrap {
      width: 100%;
      margin-bottom: 30px;
    }
    .s003 form .inner-form .input-field.second-wrap input {
      border: 1px solid rgba(255, 255, 255, 0.3);
    }
    .s003 form .inner-form .input-field.third-wrap {
      margin-bottom: 0;
      width: 100%;
    }
  }

  .row:hover {
    background-color: #ececff;
    cursor: pointer;
  }
  .row.header:hover {
    background-color: #6c7ae0 !important;
  }

</style>
