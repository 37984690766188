<template>
  <transition name="fade">
    <div
      v-if="show"
      class="gdprArea"
    >
      <div class="gdprArea_inner">
        <!-- eslint-disable -->
        <!-- eslint-enable -->
        <!-- eslint-disable-line -->
        <!-- eslint-disable-next-line -->
        <p> هذا الموقع يقوم باستخدام الكوكيز. إذا استمرّيت بالتصفّح، فإنّك توافق على استخدامنا للكوكيز. </p>
        <button
          type="submit"
          @click="accept()"
        >
          موافق
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Gdpr',
  data() {
    return {
      show: true,
    };
  },
  methods: {
    accept() {
      this.show = false;
      this.$cookies.set('GDPRI', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.gdprArea {
  &_inner{
      // display: none;
      font: 500 13px/18px BoutrosMBCDinkum,sans-serif;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      position: fixed;
      transition: -webkit-transform .2s ease-out;
      transition: transform .2s ease-out;
      bottom: 0;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      background: #2d132c;
      color: #f1f1f1;
      z-index: 10000000;
      padding: 15px 0;
    p {
      display: inline-block;
      text-align: right;
      padding: 3px 10px;
      line-height: 21px;
      margin-left: 30px;
      max-width: 50%;
      margin-bottom: 0;
      margin-right: -55px;
    }
    button {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      background: #6f0f2e;
      color: #fff;
      border-radius: 4px;
      border: 1px solid #eceff1;
      width: 60px;
      height: 25px;
      padding: 0px 8px 3px;
      line-height: 0;
      margin-top: .05%;
      &:hover {background: #c0392b;}
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .gdprArea_inner button {
    position: absolute;
  }
}

</style>
