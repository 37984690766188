import { initializeApp } from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';

const app = initializeApp({
  // apiKey: 'AIzaSyA93jD2KuIv5mqW-qHuN030it49q6ut4LY',
  // authDomain: 'blood-20162.firebaseapp.com',
  // databaseURL: 'https://blood-20162.firebaseio.com',
  // projectId: 'blood-20162',
  // storageBucket: 'blood-25110.appspot.com',
  // messagingSenderId: '263540631231',
  // appId: '1:263540631231:web:736515bd6ca1a11e',
  apiKey: 'AIzaSyCmc05KQs8yeVnxD6S7rz08_vIpQ2mdzj4',
  authDomain: 'blood-5e482.firebaseapp.com',
  databaseURL: 'https://blood-5e482.firebaseio.com',
  projectId: 'blood-5e482',
  storageBucket: '',
  messagingSenderId: '593054063909',
  appId: '1:593054063909:web:29c216a4dc9a9c5cabcecc',
  measurementId: 'G-H30X34PJGJ',
});
export default app.firestore();

export const db = app.database();
export const isCountries = db.ref('countries');
export const isBenefactor = db.ref('benefactor');
