<template>
  <div class="hello">
    <div class="limiter">
      <div class="container-table100">
        <div class="wrap-table100">
          <div id="nav">
            <router-link
              to="/"
              class="homeAreaLink"
            >
              <div class="isIcon homeArea">
                <svg
                  class="svg-icon"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M18.121,9.88l-7.832-7.836c-0.155-0.158-0.428-0.155-0.584,
                  0L1.842,9.913c-0.262,0.263-0.073,0.705,0.292,0.705h2.069v7.042c0,
                  0.227,0.187,0.414,0.414,0.414h3.725c0.228,0,0.414-0.188,
                  0.414-0.414v-3.313h2.483v3.313c0,0.227,0.187,0.414,0.413,
                  0.414h3.726c0.229,0,0.414-0.188,0.414-0.414v-7.042h2.068h0.004C18.331,
                  10.617,18.389,10.146,18.121,9.88 M14.963,
                  17.245h-2.896v-3.313c0-0.229-0.186-0.415-0.414-0.415H8.342c-0.228,
                  0-0.414,0.187-0.414,0.415v3.313H5.032v-6.628h9.931V17.245z M3.133,
                  9.79l6.864-6.868l6.867,6.868H3.133z"
                  />
                </svg>
              </div>
            </router-link>
            <div class="bloDet text-white">
              <strong>بنك الدم <span class="badge badge-pill badge-secondary">تجريبي</span></strong>
              <small>نقطة دم = حياة</small>
            </div>
            <div class="Buy_Coffee">
              <div class="innerBuy_Coffee">
                <a
                  href="https://twitter.com/ashmawisami?ref_src=twsrc%5Etfw"
                  class="twitter-follow-button"
                  data-show-count="false"
                >Follow @ashmawisami</a>
                <div class="btn-container">
                  <a
                    title="Support me on ko-fi.com"
                    class="kofi-button"
                    style="background-color:#8f1f1f;"
                    href="https://ko-fi.com/D1D81LD43"
                    target="_blank"
                  >
                    <span class="kofitext">
                      اشتري لي قهوة
                      <img
                        src="https://cdn.ko-fi.com/cdn/cup-border.png"
                        class="kofiimg"
                      >
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="s003">
            <form>
              <strong class="bloDet text-white">ابحث عن متبرع بالدم</strong>
              <div class="inner-form">
                <div class="input-field first-wrap col-md-5">
                  <div class="input-select">
                    <select
                      v-model="vSearchCity"
                      name="blood_type"
                      class="form-control"
                      @change="onChange($event)"
                    >
                      <option
                        value
                        hidden
                        disabled
                        selected
                      >
                        المحافظة
                      </option>
                      <option value="القاهرة">
                        القاهرة
                      </option>
                      <option value="الجيزة">
                        الجيزة
                      </option>
                      <option value="الشرقية">
                        الشرقية
                      </option>
                      <option value="الدقهلية">
                        الدقهلية
                      </option>
                      <option value="البحيرة">
                        البحيرة
                      </option>
                      <option value="المنيا">
                        المنيا
                      </option>
                      <option value="القليوبية">
                        القليوبية
                      </option>
                      <option value="الإسكندرية">
                        الإسكندرية
                      </option>
                      <option value="الغربية">
                        الغربية
                      </option>
                      <option value="سوهاج">
                        سوهاج
                      </option>
                      <option value="أسيوط">
                        أسيوط
                      </option>
                      <option value="المنوفية">
                        المنوفية
                      </option>
                      <option value="كفر الشيخ">
                        كفر الشيخ
                      </option>
                      <option value="الفيوم">
                        الفيوم
                      </option>
                      <option value="قنا">
                        قنا
                      </option>
                      <option value="بني سويف">
                        بني سويف
                      </option>
                      <option value="أسوان">
                        أسوان
                      </option>
                      <option value="دمياط">
                        دمياط
                      </option>
                      <option value="الإسماعيلية">
                        الإسماعيلية
                      </option>
                      <option value="الأقصر">
                        الأقصر
                      </option>
                      <option value="بورسعيد">
                        بورسعيد
                      </option>
                      <option value="السويس">
                        السويس
                      </option>
                      <option value="مطروح">
                        مطروح
                      </option>
                      <option value="شمال سيناء">
                        شمال سيناء
                      </option>
                      <option value="البحر الأحمر">
                        البحر الأحمر
                      </option>
                      <option value="الوادي الجديد">
                        الوادي الجديد
                      </option>
                      <option value="جنوب سيناء">
                        جنوب سيناء
                      </option>
                    </select>
                  </div>
                </div>

                <div class="input-field first-wrap col-md-4">
                  <select
                    id="inputRegion"
                    v-model="vSearchRegion"
                    class="form-control"
                    :disabled="!vSearchCity"
                    required
                  >
                    <option
                      value="all"
                      selected
                    >
                      المدينة / المركز / الحي
                    </option>
                    <option
                      value="all"
                      selected
                    >
                      تحديد الكل
                    </option>
                    <option
                      v-for="region in allRegion"
                      :key="region['.region']"
                    >
                      {{ region }}
                    </option>
                  </select>
                </div>

                <div class="input-field first-wrap col-md-3">
                  <div class="input-select">
                    <select
                      v-model="vSearchBloodType"
                      name="choices-single-defaul"
                      class="form-control"
                      :disabled="!vSearchCity"
                    >
                      <option
                        value
                        hidden
                        disabled
                        selected
                      >
                        اختــر فصيلة الدم
                      </option>
                      <option value="Op">
                        +O
                      </option>
                      <option value="Om">
                        -O
                      </option>
                      <option value="Bp">
                        +B
                      </option>
                      <option value="Bm">
                        -B
                      </option>
                      <option value="Ap">
                        +A
                      </option>
                      <option value="Am">
                        -A
                      </option>
                      <option value="ABp">
                        +AB
                      </option>
                      <option value="ABm">
                        -AB
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="customDie">
            <div class="customDie_inner">
              <div class="shareArea">
                <span>
                  <strong>شارك مع الاصدقاء:</strong>
                </span>
                <span id="share-buttons">
                  <!-- Facebook -->
                  <a
                    href="javascript: void(0)"
                    @click="popupShare('http://www.facebook.com/sharer.php?u='+urlBas)"
                  >
                    <svg
                      class="svg-icon"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="#fff"
                        d="M11.344,5.71c0-0.73,0.074-1.122,1.199-1.122h1.502V1.871h-2.404c-2.886,0-3.903,1.36-3.903,3.646v1.765h-1.8V10h1.8v8.128h3.601V10h2.403l0.32-2.718h-2.724L11.344,5.71z"
                      />
                    </svg>
                  </a>
                  <!-- Twitter -->
                  <a
                    href="javascript: void(0)"
                    @click="popupShare('https://twitter.com/share?url='+urlBas+'&amp;text=Simple%20Share%20Buttons&amp;hashtags=simplesharebuttons')"
                  >
                    <svg
                      class="svg-icon"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="#fff"
                        d="M18.258,3.266c-0.693,0.405-1.46,0.698-2.277,0.857c-0.653-0.686-1.586-1.115-2.618-1.115c-1.98,0-3.586,1.581-3.586,3.53c0,0.276,0.031,0.545,0.092,0.805C6.888,7.195,4.245,5.79,2.476,3.654C2.167,4.176,1.99,4.781,1.99,5.429c0,1.224,0.633,2.305,1.596,2.938C2.999,8.349,2.445,8.19,1.961,7.925C1.96,7.94,1.96,7.954,1.96,7.97c0,1.71,1.237,3.138,2.877,3.462c-0.301,0.08-0.617,0.123-0.945,0.123c-0.23,0-0.456-0.021-0.674-0.062c0.456,1.402,1.781,2.422,3.35,2.451c-1.228,0.947-2.773,1.512-4.454,1.512c-0.291,0-0.575-0.016-0.855-0.049c1.588,1,3.473,1.586,5.498,1.586c6.598,0,10.205-5.379,10.205-10.045c0-0.153-0.003-0.305-0.01-0.456c0.7-0.499,1.308-1.12,1.789-1.827c-0.644,0.28-1.334,0.469-2.06,0.555C17.422,4.782,17.99,4.091,18.258,3.266"
                      />
                    </svg>
                  </a>
                  <!-- Email -->
                  <a
                    href="mailto:?Subject=Simple Share Buttons&amp;Body=I%20saw%20this%20and%20thought%20of%20you!%20 https://simplesharebuttons.com"
                  >
                    <svg
                      class="svg-icon"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="#fff"
                        d="M16.999,4.975L16.999,4.975C16.999,4.975,16.999,4.975,16.999,4.975c-0.419-0.4-0.979-0.654-1.604-0.654H4.606c-0.584,0-1.104,0.236-1.514,0.593C3.076,4.928,3.05,4.925,3.037,4.943C3.034,4.945,3.035,4.95,3.032,4.953C2.574,5.379,2.276,5.975,2.276,6.649v6.702c0,1.285,1.045,2.329,2.33,2.329h10.79c1.285,0,2.328-1.044,2.328-2.329V6.649C17.724,5.989,17.441,5.399,16.999,4.975z M15.396,5.356c0.098,0,0.183,0.035,0.273,0.055l-5.668,4.735L4.382,5.401c0.075-0.014,0.145-0.045,0.224-0.045H15.396z M16.688,13.351c0,0.712-0.581,1.294-1.293,1.294H4.606c-0.714,0-1.294-0.582-1.294-1.294V6.649c0-0.235,0.081-0.445,0.192-0.636l6.162,5.205c0.096,0.081,0.215,0.122,0.334,0.122c0.118,0,0.235-0.041,0.333-0.12l6.189-5.171c0.099,0.181,0.168,0.38,0.168,0.6V13.351z"
                      />
                    </svg>
                  </a>
                </span>
              </div>

              <div class="allBloo text-left">
                <span>
                  <router-link
                    to="/volunteer"
                    class="volunteerLink"
                  >متبرع جديد</router-link>
                </span>
              </div>
            </div>
          </div>

          <div class="table">
            <div
              v-if="findBenefactor.length"
              class="row header"
            >
              <div class="cell">
                الإســـم
              </div>
              <div class="cell">
                العنــوان
              </div>
              <div class="cell">
                الهاتف
              </div>
              <div class="cell">
                فصيلة الدم
              </div>
              <div class="cell">
                نوع التواصل
              </div>
              <div class="cell">
                وقت الإتصــال
              </div>
            </div>

            <div v-if="!findBenefactor.length && vSearchBloodType.length">
              <div class="row notFund">
                <div class="cell">
                  <h6>
                    لا يوجد متبرع في
                    <span class="badge badge-pill badge-secondary">{{ vSearchCity }}</span>لفصيلة دم
                    <span
                      class="badge badge-pill badge-danger"
                    >{{ bloodType(vSearchBloodType) }}</span>
                  </h6>
                </div>
              </div>
            </div>

            <div class="rowsArea">
              <div
                v-for="benefactor of findBenefactor"
                :key="benefactor['.key']"
                class="row"
              >
                <div class="cell">
                  {{ benefactor.name }}
                </div>
                <div class="cell">
                  {{ benefactor.city }}
                  <span v-if="benefactor.region != 'all'">/ {{ benefactor.region }}</span>
                </div>
                <div class="cell">
                  <a :href="`tel:${benefactor.phone}`">{{ benefactor.phone }}</a>
                </div>
                <div class="cell">
                  <span class="badge badge-pill badge-danger">{{ bloodType(benefactor.bloodType) }}</span>
                </div>
                <div class="cell">
                  {{ bloodType(benefactor.contactType) }}
                </div>
                <div class="cell">
                  {{ benefactor.phonbloodTimee }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isBenefactor } from '../firebase';
export default {
  name: 'HelloWorld',
  data() {
    return {
      allRegion: [],
      vSearchRegion: 'all',
      benefactors: [],
      vSearchCity: '',
      vSearchBloodType: '',
      urlBas: 'https://blood.ashmawi.work',
    };
  },

  firebase: {
    benefactors: isBenefactor
      .orderByChild('isReal')
      .equalTo(true),
  },
  computed: {
    // eslint-disable-next-line
    findBenefactor: function() {
      if (this.vSearchCity) {
        return (
          this.benefactors
            // eslint-disable-next-line
            .filter(benefactor => {
              return benefactor.city.match(this.vSearchCity);
            })
            // eslint-disable-next-line
            .filter(benefactor => {
              if (this.vSearchRegion == 'all') {
                return benefactor.region;
              }
              return benefactor.region.match(this.vSearchRegion);
            })
            // eslint-disable-next-line
            .filter(benefactor => {
              return benefactor.bloodType.match(this.vSearchBloodType);
            })
        );
      }
      return [];
    },
  },
  methods: {
    // eslint-disable-next-line
    bloodType: function(typeis) {
      if (typeis === 'Op') {
        return '+O';
      }
      if (typeis === 'Om') {
        return '-O';
      }
      if (typeis === 'Bp') {
        return '+B';
      }
      if (typeis === 'Bm') {
        return '-B';
      }
      if (typeis === 'Ap') {
        return '+A';
      }
      if (typeis === 'Am') {
        return '-A';
      }
      if (typeis === 'ABp') {
        return '+AB';
      }
      if (typeis === 'ABm') {
        return '-AB';
      }
      if (typeis === 'call') {
        return 'اتصال';
      }
      if (typeis === 'sms') {
        return 'رســائل';
      }
      if (typeis === 'all') {
        return 'اتصـــال او رســائل';
      }
    },
    popupShare: function popup(url) {
      const width = 600;
      const height = 300;
      const left = (screen.width - width) / 2;
      const top = (screen.height - height) / 2;
      let params = `width=${width}, height=${height}`;
      params += `, top=${top}, left=${left}`;
      params += ', directories=no';
      params += ', location=no';
      params += ', menubar=no';
      params += ', resizable=no';
      params += ', scrollbars=no';
      params += ', status=no';
      params += ', toolbar=no';
      this.newwin = window.open(url, 'windowname5', params);
      if (window.focus) {
        this.newwin.focus();
      }
      return false;
    },

    onChange(event) {
      const v = event.target.value;
      this.vSearchRegion = 'all';

      if (v == 'القاهرة') {
        this.allRegion = [
          'المرج',
          'المطرية',
          'عين شمس',
          'السلام',
          'النزهة',
          'مصر الجديدة',
          'مدينة نصر',
          'منشأة ناصر',
          'الوايلي',
          'باب الشعرية',
          'الموسكى',
          'الأزبكية',
          'عابدين',
          'بولاق',
          'الزيتون',
          'حدائق القبة',
          'الزاوية الحمراء',
          'الشرابية',
          'شبرا',
          'روض الفرج',
          'السيدة زينب',
          'مصر القديمة',
          'الخليفة',
          'المقطم',
          'البساتين',
          'دار السلام',
          'المعادي',
          'طرة',
          'حلوان',
          'التبين',
        ];
      }

      if (v == 'الجيزة') {
        this.allRegion = [
          'منشأة القناطر',
          'أوسيم',
          'كرداسة',
          'أبو النمرس',
          'الحوامدية',
          'البدرشين',
          'العياط',
          'الصف',
          'أطفيح',
        ];
      }

      if (v == 'الشرقية') {
        this.allRegion = [
          'مدينة الزقازيق',
          'الحسينية (الشرقية)',
          'فاقوس',
          'بلبيس',
          'منيا القمح',
          'أبو حماد',
          'العاشر من رمضان',
          'الصالحية الجديدة',
          'كفر صقر',
          'أبو كبير',
          'القنايات',
          'مشتول السوق',
          'ديرب نجم',
          'الإبراهيمية',
          'ههيا',
          'القرين',
          'صان الحجر',
          'العزيزية',
        ];
      }

      if (v == 'الدقهلية') {
        this.allRegion = [
          'المنصورة',
          'المنزلة',
          'الجمالية',
          'دكرنس',
          'منية النصر',
          'ميت سلسيل',
          'ميت غمر',
          'أجا',
          'طلخا',
          'بلقاس',
          'السنبلاوين',
          'شربين',
          'بني عبيد',
          'المطرية',
          'نبروه',
          'جمصة',
          'الكردي',
        ];
      }

      if (v == 'البحيرة') {
        this.allRegion = [
          'رشيد',
          'شبراخيت',
          'ايتاي البارود',
          'أبو حمص',
          'حوش عيسى',
          'كفر الدوار',
          'الدلنجات',
          'كوم حمادة',
          'دمنهور',
          'المحمودية',
          'إدكو',
          'أبو المطامير',
          'الرحمانية',
          'النوبارية الجديدة',
          'وادي النطرون',
        ];
      }

      if (v == 'المنيا') {
        this.allRegion = [
          'مغاغة',
          'بني مزار',
          'مطاي',
          'سمالوط',
          'مدينة المنيا',
          'أبو قرقاص',
          'ملوي',
          'دير مواس',
          'العدوة',
        ];
      }

      if (v == 'القليوبية') {
        this.allRegion = [
          'بنها',
          'قليوب',
          'القناطر الخيرية',
          'شبرا الخيمة',
          'الخانكة',
          'كفر شكر',
          'شبين القناطر',
          'طوخ',
          'العبور (قليوبية)',
          'قها (قليوبية)',
          'الخصوص',
        ];
      }

      if (v == 'الإسكندرية') {
        this.allRegion = [
          'المنتزة',
          'الرمل',
          'كرموس',
          'محرم بك',
          'العطارين',
          'الجمرك',
          'العجمي',
          'العامرية',
          'سيدي جابر',
          'المنشية',
          'مينا البصل',
          'العامرية',
          'الدخيلة',
          'برج العرب',
        ];
      }

      if (v == 'الغربية') {
        this.allRegion = [
          'كفر الزيات',
          'السنطة',
          'المحلة الكبرى',
          'بسيون',
          'زفتى',
          'سمنود',
          'طنطا',
          'قطور',
        ];
      }

      if (v == 'سوهاج') {
        this.allRegion = [
          'أخميم',
          'دار السلام',
          'مراغة',
          'طهطا',
          'طما',
          'جهينة',
          'المنشأة',
          'جرجا',
          'البلينا',
        ];
      }

      if (v == 'أسيوط') {
        this.allRegion = [
          'مدينة أسيوط',
          'ديروط',
          'القوصية',
          'أبنوب',
          'منفلوط',
          'الفتح',
          'الغنايم',
          'ساحل سليم',
          'البداري',
          'صدفا',
        ];
      }

      if (v == 'المنوفية') {
        this.allRegion = ['شبين الكوم', 'منوف', 'أشمون', 'قويسنا', 'تلا'];
      }

      if (v == 'كفر الشيخ') {
        this.allRegion = [
          'فوه',
          'مطوبس',
          'قلين',
          'سيدي سالم',
          'الرياض',
          'بيلا',
          'الحامول',
          'البرلس',
        ];
      }

      if (v == 'الفيوم') {
        this.allRegion = [
          'مدينة الفيوم',
          'سنورس',
          'ابشواى',
          'اطسا',
          'طامية',
          'يوسف الصديق',
        ];
      }

      if (v == 'قنا') {
        this.allRegion = [
          'مدينة قنا',
          'أبو تشت',
          'فرشوط',
          'نجع حمادي',
          'الوقف',
          'دشنا',
          'قفط',
          'قوص',
          'نقادة',
        ];
      }

      if (v == 'بني سويف') {
        this.allRegion = [
          'الواسطى',
          'مدينة بني سويف',
          'ناصر (بوش)',
          'إهناسيا',
          'ببا',
          'سمسطا',
          'الفشن',
          'بني سويف الجديدة',
        ];
      }

      if (v == 'أسوان') {
        this.allRegion = ['مدينة أسوان', 'دراو', 'كوم أمبو', 'نصر النوبة'];
      }

      if (v == 'دمياط') {
        this.allRegion = [
          'مدينة دمياط',
          'دمياط الجديدة',
          'فارسكور',
          'كفر سعد',
          'الزرقا',
          'كفر البطيخ',
          'رأس البر',
        ];
      }

      if (v == 'الإسماعيلية') {
        this.allRegion = [
          'مدينة الإسماعيلية',
          'التل الكبير',
          'فايد',
          'القنطرة شرق',
          'القنطرة غرب',
          'أبوصوير',
          'القصاصين',
        ];
      }

      if (v == 'الأقصر') {
        this.allRegion = [
          'مدينة الأقصر',
          'مدينة الأقصر الجديدة',
          'مدينة طيبة الجديدة',
          'الزينية',
          'البياضية',
          'القرنة',
          'أرمنت',
          'الطود',
          'إسنا',
        ];
      }

      if (v == 'بورسعيد') {
        this.allRegion = ['بورفؤاد', 'المناخ', 'الضواحي', 'الزهور', 'العرب'];
      }

      if (v == 'السويس') {
        this.allRegion = [
          'مدينة السويس',
          'الأربعين',
          'عتاقة',
          'الجناين',
          'فيصل',
        ];
      }

      if (v == 'مطروح') {
        this.allRegion = [
          'العلمين',
          'الضبعة',
          'الحمام',
          'مرسى مطروح',
          'النجيلة',
          'براني',
          'السلوم',
          'سيوة',
        ];
      }

      if (v == 'شمال سيناء') {
        this.allRegion = [
          'بئر العبد',
          'نخل',
          'الحسنة',
          'العريش',
          'الشيخ زويد',
          'رفح',
        ];
      }

      if (v == 'البحر الأحمر') {
        this.allRegion = [
          'الغردقة',
          'سفاجا',
          'مرسى علم',
          'القصير',
          'رأس غارب',
          'شلاتين',
        ];
      }

      if (v == 'الوادي الجديد') {
        this.allRegion = ['الخارجة', 'باريس', 'بلاط', 'الداخلة', 'الفرافرة'];
      }

      if (v == 'جنوب سيناء') {
        this.allRegion = [
          'شرم الشيخ',
          'دهب',
          'الطور',
          'طابا',
          'نويبع',
          'سانت كاترين',
          'أبو رديس',
          'أبو زنيمة',
          'رأس سدر',
        ];
      }
    },
  },
};
</script>

<style scoped lang="scss">
#share-buttons .svg-icon {
  width: 20px;
}
#share-buttons a {
  margin: 0 5px;
  cursor: pointer;
}
.isIcon {
  width: 40px;
  height: 40px;
  line-height: 35px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  -webkit-transition: background 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  -moz-transition: background 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  -ms-transition: background 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: background 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: background 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}
.isIcon:hover {
  background: rgba(0, 0, 0, 0.39);
}

.svg-icon {
  width: 26px;
  fill: #fff;
}
.plusAd {
  color: #fff;
  position: absolute;
  left: 5px;
}
.bloDet {
  line-height: 40px;
  margin-top: -20px;
  small {
    display: block;
    line-height: 0;
    text-align: right;
  }
}
.isIcon.homeArea {
  float: right;
}
.isIcon.volunteer {
  float: left;
}
.isIcon.volunteer .svg-icon {
  margin-left: 6px;
}
.customDie .customDie_inner {
  text-align: right;
  padding: 10px 20px !important;
  background-color: #6f0f2e;
  font-size: 13px;
  color: #fff;
  .shareArea {
    float: right;
  }
  .allBloo {
    span {
      margin: 0 5px 0 0;
    }
    a {
      color: #fff;
      &:hover {
        color: #ffb6ae;
      }
    }
  }
}
.customDie:hover {
  cursor: inherit;
}
.row.notFund {
  span {
    margin: 0 5px;
  }
}

/* ------------------------------------ */
a {
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
}

/* ------------------------------------ */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/* ------------------------------------ */
input {
  display: block;
  outline: none;
  border: none !important;
}

textarea {
  display: block;
  outline: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

/* ------------------------------------ */
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
  [ Table ]*/

.limiter {
  width: 100%;
  margin: 0 auto;
}
.table {
  width: 100%;
  display: table;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .table {
    display: block;
  }
}

.row {
  display: inline-table;
  background: #fff;
}

.row.header {
  color: #ffffff;
  background: #801336;
}

@media screen and (max-width: 768px) {
  .row {
    display: block;
  }

  .row.header {
    padding: 0;
    height: 0px;
  }

  .row.header .cell {
    display: none;
  }

  .row .cell:before {
    font-size: 12px;
    color: #808080;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: unset !important;

    margin-bottom: 13px;
    content: attr(data-title);
    min-width: 98px;
    display: block;
  }
}

.cell {
  display: table-cell;
}

@media screen and (max-width: 768px) {
  .cell {
    display: block;
  }
}

.row .cell {
  font-size: 15px;
  color: #666666;
  line-height: 1.2;
  font-weight: unset !important;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f2f2f2;
}

.row.header .cell {
  font-size: 18px;
  color: #fff;
  line-height: 1.2;
  font-weight: unset !important;
  padding-top: 19px;
  padding-bottom: 19px;
}

.row .cell:nth-child(1) {
  width: 180px;
  padding-left: 15px;
  padding-right: 15px;
}

.row .cell:nth-child(2) {
  width: 170px;
}

.row .cell:nth-child(3) {
  width: 170px;
}

.row .cell:nth-child(4) {
  width: 90px;
}

.table,
.row {
  width: 100% !important;
}
@media (max-width: 768px) {
  .row {
    border-bottom: 1px solid #f2f2f2;
    margin: 0;
  }

  .customDie .cell {
    strong {
      font-size: 14px;
    }
    &:before {
      display: none;
    }
  }

  .row.notFund .cell {
    padding: 15px 10px !important;
    &:before {
      display: none;
    }
    h6 {
      font-size: 14px;
    }
  }

  .row .cell {
    padding-left: 0;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .row .cell:nth-child(1) {
    padding-left: 0;
  }

  .row .cell {
    font-size: 18px;
    color: #555555;
    line-height: 1.2;
    font-weight: unset !important;
  }

  .table,
  .row,
  .cell {
    width: 100% !important;
  }
}

.s003 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #c0392b;
  background-size: cover;
  background-position: center center;
  padding: 15px;
}

.s003 form {
  width: 100%;
  max-width: 790px;
  margin-bottom: 0;
  padding: 0px 0px 15px 0px;
  strong {
    margin-bottom: 15px;
    display: inline-block;
  }
}

.s003 form .inner-form {
  background: #fff;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 15px;
}

.s003 form .inner-form .input-field input {
  height: 100%;
  background: transparent;
  border: 0;
  display: block;
  width: 100%;
  padding: 10px 32px;
  font-size: 16px;
  color: #555;
}

.s003 form .inner-form .input-field input.placeholder {
  color: #888;
  font-size: 16px;
}

.s003 form .inner-form .input-field input:-moz-placeholder {
  color: #888;
  font-size: 16px;
}

.s003 form .inner-form .input-field input::-webkit-input-placeholder {
  color: #888;
  font-size: 16px;
}

.s003 form .inner-form .input-field input:hover,
.s003 form .inner-form .input-field input:focus {
  box-shadow: none;
  outline: 0;
  border-color: #fff;
}

.s003 form .inner-form .input-field.first-wrap {
  width: 300px;
}
.s003 form .inner-form .input-field.first-wrap .choices__inner {
  background: transparent;
  border-radius: 0;
  border: 0;
  height: 100%;
  color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 30px;
}

.s003
  form
  .inner-form
  .input-field.first-wrap
  .choices__inner
  .choices__list.choices__list--single {
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.s003
  form
  .inner-form
  .input-field.first-wrap
  .choices__inner
  .choices__item.choices__item--selectable.choices__placeholder {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  opacity: 1;
  color: #888;
}

.s003
  form
  .inner-form
  .input-field.first-wrap
  .choices__inner
  .choices__list--single
  .choices__item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  color: #555;
}

.s003
  form
  .inner-form
  .input-field.first-wrap
  .choices[data-type*="select-one"]:after {
  right: 30px;
  border-color: #e5e5e5 transparent transparent transparent;
}

.s003
  form
  .inner-form
  .input-field.first-wrap
  .choices__list.choices__list--dropdown {
  border: 0;
  background: #fff;
  padding: 20px 30px;
  margin-top: 2px;
  border-radius: 4px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.s003
  form
  .inner-form
  .input-field.first-wrap
  .choices__list.choices__list--dropdown
  .choices__item--selectable {
  padding-right: 0;
}

.s003
  form
  .inner-form
  .input-field.first-wrap
  .choices__list--dropdown
  .choices__item--selectable.is-highlighted {
  background: #fff;
  color: #63c76a;
}

.s003
  form
  .inner-form
  .input-field.first-wrap
  .choices__list--dropdown
  .choices__item {
  color: #555;
  min-height: 24px;
}

.s003 form .inner-form .input-field.second-wrap {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.s003 form .inner-form .input-field.third-wrap {
  width: 74px;
  height: 37px;
  border-radius: 3px;
  overflow: hidden;
  line-height: 0;
}

.s003 form .inner-form .input-field.third-wrap .btn-search {
  height: 100%;
  width: 100%;
  white-space: nowrap;
  color: #fff;
  border: 0;
  cursor: pointer;
  background: #63c76a;
  transition: all 0.2s ease-out, color 0.2s ease-out;
}

.s003 form .inner-form .input-field.third-wrap .btn-search svg {
  width: 16px;
}

.s003 form .inner-form .input-field.third-wrap .btn-search:hover {
  background: #50c058;
}

.s003 form .inner-form .input-field.third-wrap .btn-search:focus {
  outline: 0;
  box-shadow: none;
}

@media screen and (max-width: 992px) {
  .s003 form .inner-form .input-field {
    height: 50px;
  }
}

@media screen and (max-width: 767px) {
  .s003 form .inner-form {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 20px;
  }
  .s003 form .inner-form .input-field {
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .s003 form .inner-form .input-field input {
    padding: 10px 15px;
  }
  .s003 form .inner-form .input-field.first-wrap {
    width: 100%;
    border-right: 0;
  }
  .s003 form .inner-form .input-field.first-wrap .choices__inner {
    padding: 10px 15px;
  }
  .s003
    form
    .inner-form
    .input-field.first-wrap
    .choices[data-type*="select-one"]:after {
    right: 11.5px;
    border-color: #e5e5e5 transparent transparent transparent;
  }
  .s003 form .inner-form .input-field.second-wrap {
    width: 100%;
    margin-bottom: 30px;
  }
  .s003 form .inner-form .input-field.second-wrap input {
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  .s003 form .inner-form .input-field.third-wrap {
    margin-bottom: 0;
    width: 100%;
  }
}

.row:hover {
  background-color: #ececff;
  cursor: pointer;
}
.row.header:hover {
  background-color: #801336 !important;
  cursor: inherit;
}
.wrap-table100 {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  .table {
    .cell {
      .badge {
        padding: 3px 10px;
      }
    }
  }
}
.rowsArea {
  max-height: 450px;
  overflow: auto;
  .row {
    &:nth-child(2n+2) {
      background-color: #f3f3f3;
    }
  }
}

</style>
