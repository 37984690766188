<template>
  <div class="home">
    <HelloWorld />
    <div class="cor">
      <span />
      <span />
      <span />
    </div>
  </div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue';

export default {
  name: 'Home',
  components: {
    HelloWorld,
  },
};
</script>
